(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('CampagnaDetailController', CampagnaDetailController);


    CampagnaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Campagna', 'Agenzia', 'CampagnaStatus', 'Campagna_type'];

    function CampagnaDetailController($scope, $rootScope, $stateParams, previousState, entity, Campagna, Agenzia, CampagnaStatus, Campagna_type) {

        var vm = this;

        vm.campagna = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:campagnaUpdate', function(event, result) {
            vm.campagna = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
