(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Opzioni_ticketDialogController', Opzioni_ticketDialogController);

    Opzioni_ticketDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Opzioni_ticket', 'Campagna'];

    function Opzioni_ticketDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Opzioni_ticket, Campagna) {
        var vm = this;

        vm.opzioni_ticket = entity;
        vm.clear = clear;
        vm.save = save;
        vm.campagnas = Campagna.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.opzioni_ticket.id !== null) {
                Opzioni_ticket.update(vm.opzioni_ticket, onSaveSuccess, onSaveError);
            } else {
                Opzioni_ticket.save(vm.opzioni_ticket, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:opzioni_ticketUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
