(function() {
    'use strict';
    angular
        .module('doctorclubboApp')
        .factory('Campagna_log', Campagna_log);

    Campagna_log.$inject = ['$resource', 'DateUtils'];

    function Campagna_log ($resource, DateUtils) {
        var resourceUrl =  'api/campagna-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dtins = DateUtils.convertDateTimeFromServer(data.dtins);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
