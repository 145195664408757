(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('TvDeleteController',TvDeleteController);

    TvDeleteController.$inject = ['$uibModalInstance', 'entity', 'Tv'];

    function TvDeleteController($uibModalInstance, entity, Tv) {
        var vm = this;

        vm.tv = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Tv.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
