(function() {
    'use strict';
    angular
        .module('doctorclubboApp')
        .factory('Qsched_status', Qsched_status);

    Qsched_status.$inject = ['$resource'];

    function Qsched_status ($resource) {
        var resourceUrl =  'api/qsched-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
