(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Campagnalog_tipoController', Campagnalog_tipoController);

    Campagnalog_tipoController.$inject = ['$scope', '$state', 'Campagnalog_tipo'];

    function Campagnalog_tipoController ($scope, $state, Campagnalog_tipo) {
        var vm = this;

        vm.campagnalog_tipos = [];

        loadAll();

        function loadAll() {
            Campagnalog_tipo.query(function(result) {
                vm.campagnalog_tipos = result;
            });
        }
    }
})();
