(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Recall_statusController', Recall_statusController);

    Recall_statusController.$inject = ['$scope', '$state', 'Recall_status'];

    function Recall_statusController ($scope, $state, Recall_status) {
        var vm = this;

        vm.recall_statuses = [];

        loadAll();

        function loadAll() {
            Recall_status.query(function(result) {
                vm.recall_statuses = result;
            });
        }
    }
})();
