(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('DispositivoDetailController', DispositivoDetailController);

    DispositivoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Dispositivo'];

    function DispositivoDetailController($scope, $rootScope, $stateParams, previousState, entity, Dispositivo) {
        var vm = this;

        vm.dispositivo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:dispositivoUpdate', function(event, result) {
            vm.dispositivo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
