(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Ticket_statusController', Ticket_statusController);

    Ticket_statusController.$inject = ['$scope', '$state', 'Ticket_status'];

    function Ticket_statusController ($scope, $state, Ticket_status) {
        var vm = this;

        vm.ticket_statuses = [];

        loadAll();

        function loadAll() {
            Ticket_status.query(function(result) {
                vm.ticket_statuses = result;
            });
        }
    }
})();
