(function() {
    'use strict';
    angular
        .module('doctorclubboApp')
        .factory('Queue', Queue);

    Queue.$inject = ['$resource', 'DateUtils'];

    function Queue ($resource, DateUtils) {
        var resourceUrl =  'api/queues/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dtIns = DateUtils.convertDateTimeFromServer(data.dtIns);
                        data.dtUpdate = DateUtils.convertDateTimeFromServer(data.dtUpdate);
                        data.dtFirstExecution = DateUtils.convertDateTimeFromServer(data.dtFirstExecution);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'import': { method:'POST', isArray:false, url:'api/queues/import' , params: {id: '@id'}},
            'process': { method:'POST', isArray:false, url:'api/queues/process', params: {id: '@id'} }
        });
    }
})();
