(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('RaciDetailController', RaciDetailController);

    RaciDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Raci', 'Area', 'Azione', 'CampagnaStatus'];

    function RaciDetailController($scope, $rootScope, $stateParams, previousState, entity, Raci, Area, Azione, CampagnaStatus) {
        var vm = this;

        vm.raci = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:raciUpdate', function(event, result) {
            vm.raci = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
