(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('UtenteDetailController', UtenteDetailController);

    UtenteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Utente', 'Specializzazione', 'Attivita', 'Dispositivo', 'Dispositivo_os', 'Tablet',  'Tv', 'Provincia', 'Comune', 'Recall_status', 'Agenzia'];

    function UtenteDetailController($scope, $rootScope, $stateParams, previousState, entity, Utente, Specializzazione, Attivita, Dispositivo, Dispositivo_os, Tablet,  Tv, Provincia, Comune, Recall_status, Agenzia) {
        var vm = this;

        vm.utente = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:utenteUpdate', function(event, result) {
            vm.utente = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
