(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Delivery_option_agenziaDetailController', Delivery_option_agenziaDetailController);

    Delivery_option_agenziaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Delivery_option_agenzia', 'Delivery_option', 'Agenzia'];

    function Delivery_option_agenziaDetailController($scope, $rootScope, $stateParams, previousState, entity, Delivery_option_agenzia, Delivery_option, Agenzia) {
        var vm = this;

        vm.delivery_option_agenzia = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:delivery_option_agenziaUpdate', function(event, result) {
            vm.delivery_option_agenzia = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
