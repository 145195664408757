(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('azione', {
            parent: 'entity',
            url: '/azione',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.azione.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/azione/aziones.html',
                    controller: 'AzioneController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('azione');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('azione-detail', {
            parent: 'azione',
            url: '/azione/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.azione.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/azione/azione-detail.html',
                    controller: 'AzioneDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('azione');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Azione', function($stateParams, Azione) {
                    return Azione.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'azione',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('azione-detail.edit', {
            parent: 'azione-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azione/azione-dialog.html',
                    controller: 'AzioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Azione', function(Azione) {
                            return Azione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('azione.new', {
            parent: 'azione',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azione/azione-dialog.html',
                    controller: 'AzioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('azione', null, { reload: 'azione' });
                }, function() {
                    $state.go('azione');
                });
            }]
        })
        .state('azione.edit', {
            parent: 'azione',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azione/azione-dialog.html',
                    controller: 'AzioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Azione', function(Azione) {
                            return Azione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('azione', null, { reload: 'azione' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('azione.delete', {
            parent: 'azione',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/azione/azione-delete-dialog.html',
                    controller: 'AzioneDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Azione', function(Azione) {
                            return Azione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('azione', null, { reload: 'azione' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
