(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('queue-account', {
            parent: 'entity',
            url: '/queue-account?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.queueAccount.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/queue-account/queue-accounts.html',
                    controller: 'QueueAccountController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('queueAccount');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('queue-account-detail', {
            parent: 'entity',
            url: '/queue-account/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.queueAccount.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/queue-account/queue-account-detail.html',
                    controller: 'QueueAccountDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('queueAccount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'QueueAccount', function($stateParams, QueueAccount) {
                    return QueueAccount.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'queue-account',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('queue-account-detail.edit', {
            parent: 'queue-account-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/queue-account/queue-account-dialog.html',
                    controller: 'QueueAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['QueueAccount', function(QueueAccount) {
                            return QueueAccount.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('queue-account.new', {
            parent: 'queue-account',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/queue-account/queue-account-dialog.html',
                    controller: 'QueueAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                cognome: null,
                                cf: null,
                                dtNascita: null,
                                numIscrizione: null,
                                telefono: null,
                                cellulare: null,
                                fax: null,
                                email: null,
                                email2: null,
                                attivita: null,
                                precontattogg: null,
                                precontattoOrario1: null,
                                precontattoOrario2: null,
                                ricerchegg: null,
                                ricercheOrario: null,
                                isfgg: null,
                                isfOrario: null,
                                note: null,
                                dispositivo: null,
                                dispositivoOs: null,
                                tablet: null,
                                tabletOs: null,
                                tv: null,
                                canaliTv: null,
                                comune: null,
                                cap: null,
                                indirizzo: null,
                                dtIns: null,
                                dtUpdate: null,
                                recallStatus: null,
                                statusNote: null,
                                recallDt: null,
                                spedizioneComune: null,
                                spedizioneCap: null,
                                spedizioneIndirizzo: null,
                                spedizioneTel: null,
                                source: null,
                                specializzazione1: null,
                                specializzazione2: null,
                                specializzazione3: null,
                                provincia: null,
                                spedizioneProv: null,
                                idQueue: null,
                                error_list: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('queue-account', null, { reload: 'queue-account' });
                }, function() {
                    $state.go('queue-account');
                });
            }]
        })
        .state('queue-account.edit', {
            parent: 'queue-account',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/queue-account/queue-account-dialog.html',
                    controller: 'QueueAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['QueueAccount', function(QueueAccount) {
                            return QueueAccount.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('queue-account', null, { reload: 'queue-account' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('queue-account.delete', {
            parent: 'queue-account',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/queue-account/queue-account-delete-dialog.html',
                    controller: 'QueueAccountDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['QueueAccount', function(QueueAccount) {
                            return QueueAccount.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('queue-account', null, { reload: 'queue-account' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
