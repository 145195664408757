(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('CampagnaAmazonDialogController', CampagnaAmazonDialogController);

    CampagnaAmazonDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CampagnaAmazon', 'Queue_status', 'User'];

    function CampagnaAmazonDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CampagnaAmazon, Queue_status, User) {
        var vm = this;

        vm.campagnaAmazon = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.queue_statuses = Queue_status.query();
        vm.users = User.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.campagnaAmazon.id !== null) {
                CampagnaAmazon.update(vm.campagnaAmazon, onSaveSuccess, onSaveError);
            } else {
                CampagnaAmazon.save(vm.campagnaAmazon, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:campagnaAmazonUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.datains = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
