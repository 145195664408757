(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Delivery_optionDetailController', Delivery_optionDetailController);

    Delivery_optionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Delivery_option'];

    function Delivery_optionDetailController($scope, $rootScope, $stateParams, previousState, entity, Delivery_option) {
        var vm = this;

        vm.delivery_option = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:delivery_optionUpdate', function(event, result) {
            vm.delivery_option = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
