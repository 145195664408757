(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('attivita', {
            parent: 'entity',
            url: '/attivita',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.attivita.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/attivita/attivitas.html',
                    controller: 'AttivitaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('attivita');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('attivita-detail', {
            parent: 'entity',
            url: '/attivita/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.attivita.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/attivita/attivita-detail.html',
                    controller: 'AttivitaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('attivita');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Attivita', function($stateParams, Attivita) {
                    return Attivita.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'attivita',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('attivita-detail.edit', {
            parent: 'attivita-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/attivita/attivita-dialog.html',
                    controller: 'AttivitaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Attivita', function(Attivita) {
                            return Attivita.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('attivita.new', {
            parent: 'attivita',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/attivita/attivita-dialog.html',
                    controller: 'AttivitaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                attivita: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('attivita', null, { reload: 'attivita' });
                }, function() {
                    $state.go('attivita');
                });
            }]
        })
        .state('attivita.edit', {
            parent: 'attivita',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/attivita/attivita-dialog.html',
                    controller: 'AttivitaDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Attivita', function(Attivita) {
                            return Attivita.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('attivita', null, { reload: 'attivita' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('attivita.delete', {
            parent: 'attivita',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/attivita/attivita-delete-dialog.html',
                    controller: 'AttivitaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Attivita', function(Attivita) {
                            return Attivita.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('attivita', null, { reload: 'attivita' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
