(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('CampagnaStatusDetailController', CampagnaStatusDetailController);

    CampagnaStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CampagnaStatus'];

    function CampagnaStatusDetailController($scope, $rootScope, $stateParams, previousState, entity, CampagnaStatus) {
        var vm = this;

        vm.campagnaStatus = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:campagnaStatusUpdate', function(event, result) {
            vm.campagnaStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
