(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Queue_creditDeleteController',Queue_creditDeleteController);

    Queue_creditDeleteController.$inject = ['$uibModalInstance', 'entity', 'Queue_credit'];

    function Queue_creditDeleteController($uibModalInstance, entity, Queue_credit) {
        var vm = this;

        vm.queue_credit = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Queue_credit.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
