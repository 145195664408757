(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Ticket_statusDeleteController',Ticket_statusDeleteController);

    Ticket_statusDeleteController.$inject = ['$uibModalInstance', 'entity', 'Ticket_status'];

    function Ticket_statusDeleteController($uibModalInstance, entity, Ticket_status) {
        var vm = this;

        vm.ticket_status = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Ticket_status.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
