(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('GcSystemDialogController', GcSystemDialogController);

    GcSystemDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'GcSystem'];

    function GcSystemDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, GcSystem) {
        var vm = this;

        vm.gcSystem = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.gcSystem.id !== null) {
                GcSystem.update(vm.gcSystem, onSaveSuccess, onSaveError);
            } else {
                GcSystem.save(vm.gcSystem, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:gcSystemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
