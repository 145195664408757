(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Dispositivo_osDialogController', Dispositivo_osDialogController);

    Dispositivo_osDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Dispositivo_os'];

    function Dispositivo_osDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Dispositivo_os) {
        var vm = this;

        vm.dispositivo_os = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.dispositivo_os.id !== null) {
                Dispositivo_os.update(vm.dispositivo_os, onSaveSuccess, onSaveError);
            } else {
                Dispositivo_os.save(vm.dispositivo_os, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:dispositivo_osUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
