(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('UtenteDialogController', UtenteDialogController);

    UtenteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Utente', 'Specializzazione', 'Attivita', 'Dispositivo', 'Dispositivo_os', 'Tablet',  'Tv', 'Provincia', 'Comune', 'Recall_status', 'Agenzia'];

    function UtenteDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Utente, Specializzazione, Attivita, Dispositivo, Dispositivo_os, Tablet,  Tv, Provincia, Comune, Recall_status, Agenzia) {
        var vm = this;

        vm.utente = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.specializzaziones = Specializzazione.query();
        vm.attivitas = Attivita.query();
        vm.dispositivos = Dispositivo.query();
        vm.dispositivo_os = Dispositivo_os.query();
        vm.tablets = Tablet.query();
        vm.tablet_os = Dispositivo_os.query();
        vm.tvs = Tv.query();
        vm.provincias = Provincia.query();
        vm.comunes = Comune.query();
        vm.recall_statuses = Recall_status.query();
        vm.agenzias = Agenzia.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.utente.id !== null) {
                Utente.update(vm.utente, onSaveSuccess, onSaveError);
            } else {
                Utente.save(vm.utente, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:utenteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dtNascita = false;
        vm.datePickerOpenStatus.dtIns = false;
        vm.datePickerOpenStatus.dtUpdate = false;
        vm.datePickerOpenStatus.recallDt = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
