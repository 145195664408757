(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('CampagnaAmazonDetailController', CampagnaAmazonDetailController);

    CampagnaAmazonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CampagnaAmazon', 'Queue_status', 'User'];

    function CampagnaAmazonDetailController($scope, $rootScope, $stateParams, previousState, entity, CampagnaAmazon, Queue_status, User) {
        var vm = this;

        vm.campagnaAmazon = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:campagnaAmazonUpdate', function(event, result) {
            vm.campagnaAmazon = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
