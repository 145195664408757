(function() {
    'use strict';
    angular
        .module('doctorclubboApp')
        .factory('Utente', Utente);

    Utente.$inject = ['$resource', 'DateUtils'];

    function Utente ($resource, DateUtils) {
        var resourceUrl =  'api/utentes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dtNascita = DateUtils.convertDateTimeFromServer(data.dtNascita);
                        data.dtIns = DateUtils.convertDateTimeFromServer(data.dtIns);
                        data.dtUpdate = DateUtils.convertDateTimeFromServer(data.dtUpdate);
                        data.recallDt = DateUtils.convertDateTimeFromServer(data.recallDt);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
