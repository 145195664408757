(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Campagna_logDetailController', Campagna_logDetailController);

    Campagna_logDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Campagna_log', 'Campagna', 'Campagnalog_tipo'];

    function Campagna_logDetailController($scope, $rootScope, $stateParams, previousState, entity, Campagna_log, Campagna, Campagnalog_tipo) {
        var vm = this;

        vm.campagna_log = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:campagna_logUpdate', function(event, result) {
            vm.campagna_log = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
