(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Fee_agenziaDialogController', Fee_agenziaDialogController);

    Fee_agenziaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Fee_agenzia', 'Fee', 'Agenzia'];

    function Fee_agenziaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Fee_agenzia, Fee, Agenzia) {
        var vm = this;

        vm.fee_agenzia = entity;
        vm.clear = clear;
        vm.save = save;
        vm.fees = Fee.query();
        vm.agenzias = Agenzia.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.fee_agenzia.id !== null) {
                Fee_agenzia.update(vm.fee_agenzia, onSaveSuccess, onSaveError);
            } else {
                Fee_agenzia.save(vm.fee_agenzia, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:fee_agenziaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
