(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('QueueProcessController',QueueProcessController);

    QueueProcessController.$inject = ['$uibModalInstance', 'entity', 'Queue'];

    function QueueProcessController($uibModalInstance, entity, Queue) {
        var vm = this;

        vm.queue = entity;
        vm.clear = clear;
        vm.confirmProcess = confirmProcess;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmProcess (id) {       	
        	
        	 Queue.process({id: id},
                     function () {
                         $uibModalInstance.close(true);
                     });
        }
        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:queueProcess', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        
    }
})();
