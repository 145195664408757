(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Dispositivo_osController', Dispositivo_osController);

    Dispositivo_osController.$inject = ['$scope', '$state', 'Dispositivo_os'];

    function Dispositivo_osController ($scope, $state, Dispositivo_os) {
        var vm = this;

        vm.dispositivo_os = [];

        loadAll();

        function loadAll() {
            Dispositivo_os.query(function(result) {
                vm.dispositivo_os = result;
            });
        }
    }
})();
