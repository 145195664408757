(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('AzioneDeleteController',AzioneDeleteController);

    AzioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'Azione'];

    function AzioneDeleteController($uibModalInstance, entity, Azione) {
        var vm = this;

        vm.azione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Azione.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
