(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('QueueDetailController', QueueDetailController);


    QueueDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Queue', 'QueueStatus', 'QueueType', 'User'];

    function QueueDetailController($scope, $rootScope, $stateParams, previousState, entity, Queue, QueueStatus, QueueType, User) {

        var vm = this;

        vm.queue = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:queueUpdate', function(event, result) {
            vm.queue = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
