(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('AmazonStatementDetailController', AmazonStatementDetailController);

    AmazonStatementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AmazonStatement', 'GcSystem', 'CampagnaAmazon'];

    function AmazonStatementDetailController($scope, $rootScope, $stateParams, previousState, entity, AmazonStatement, GcSystem, CampagnaAmazon) {
        var vm = this;

        vm.amazonStatement = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:amazonStatementUpdate', function(event, result) {
            vm.amazonStatement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
