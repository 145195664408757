(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Queue_creditDetailController', Queue_creditDetailController);

    Queue_creditDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Queue_credit'];

    function Queue_creditDetailController($scope, $rootScope, $stateParams, previousState, entity, Queue_credit) {
        var vm = this;

        vm.queue_credit = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:queue_creditUpdate', function(event, result) {
            vm.queue_credit = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
