(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('SistemaDetailController', SistemaDetailController);

    SistemaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Sistema'];

    function SistemaDetailController($scope, $rootScope, $stateParams, previousState, entity, Sistema) {
        var vm = this;

        vm.sistema = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:sistemaUpdate', function(event, result) {
            vm.sistema = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
