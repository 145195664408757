(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('AttivitaDetailController', AttivitaDetailController);

    AttivitaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Attivita'];

    function AttivitaDetailController($scope, $rootScope, $stateParams, previousState, entity, Attivita) {
        var vm = this;

        vm.attivita = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:attivitaUpdate', function(event, result) {
            vm.attivita = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
