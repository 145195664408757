(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('StatementDeleteController',StatementDeleteController);

    StatementDeleteController.$inject = ['$uibModalInstance', 'entity', 'Statement'];

    function StatementDeleteController($uibModalInstance, entity, Statement) {
        var vm = this;

        vm.statement = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Statement.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
