(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('CampagnaStatusDeleteController',CampagnaStatusDeleteController);

    CampagnaStatusDeleteController.$inject = ['$uibModalInstance', 'entity', 'CampagnaStatus'];

    function CampagnaStatusDeleteController($uibModalInstance, entity, CampagnaStatus) {
        var vm = this;

        vm.campagnaStatus = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CampagnaStatus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
