(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('SistemaController', SistemaController);

    SistemaController.$inject = ['$scope', '$state', 'Sistema'];

    function SistemaController ($scope, $state, Sistema) {
        var vm = this;

        vm.sistemas = [];

        loadAll();

        function loadAll() {
            Sistema.query(function(result) {
                vm.sistemas = result;
            });
        }
    }
})();
