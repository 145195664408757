(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Delivery_option_agenziaDeleteController',Delivery_option_agenziaDeleteController);

    Delivery_option_agenziaDeleteController.$inject = ['$uibModalInstance', 'entity', 'Delivery_option_agenzia'];

    function Delivery_option_agenziaDeleteController($uibModalInstance, entity, Delivery_option_agenzia) {
        var vm = this;

        vm.delivery_option_agenzia = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Delivery_option_agenzia.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
