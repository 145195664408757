(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('utente', {
            parent: 'entity',
            url: '/utente?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.utente.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/utente/utentes.html',
                    controller: 'UtenteController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('utente');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('utente-detail', {
            parent: 'entity',
            url: '/utente/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'doctorclubboApp.utente.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/utente/utente-detail.html',
                    controller: 'UtenteDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('utente');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Utente', function($stateParams, Utente) {
                    return Utente.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'utente',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('utente-detail.edit', {
            parent: 'utente-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/utente/utente-dialog.html',
                    controller: 'UtenteDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Utente', function(Utente) {
                            return Utente.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('utente.new', {
            parent: 'utente',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/utente/utente-dialog.html',
                    controller: 'UtenteDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                cognome: null,
                                cf: null,
                                dtNascita: null,
                                numIscrizione: null,
                                telefono: null,
                                cellulare: null,
                                fax: null,
                                email: null,
                                email2: null,
                                specializzazione1: null,
                                specializzazione2: null,
                                specializzazione3: null,
                                attivita: null,
                                precontattogg: null,
                                precontattoOrario1: null,
                                precontattoOrario2: null,
                                ricerchegg: null,
                                ricercheOrario: null,
                                isfgg: null,
                                isfOrario: null,
                                note: null,
                                dispositivo: null,
                                dispositivoOs: null,
                                tablet: null,
                                tabletOs: null,
                                tv: null,
                                canaliTv: null,
                                provincia: null,
                                comune: null,
                                cap: null,
                                indirizzo: null,
                                dtIns: null,
                                dtUpdate: null,
                                recallStatus: null,
                                statusNote: null,
                                recallDt: null,
                                spedizioneProv: null,
                                spedizioneComune: null,
                                spedizioneCap: null,
                                spedizioneIndirizzo: null,
                                spedizioneTel: null,
                                source: null,
                                balance: null,
                                externalUserid: null,
                                agency_id: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('utente', null, { reload: 'utente' });
                }, function() {
                    $state.go('utente');
                });
            }]
        })
        .state('utente.edit', {
            parent: 'utente',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/utente/utente-dialog.html',
                    controller: 'UtenteDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Utente', function(Utente) {
                            return Utente.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('utente', null, { reload: 'utente' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('utente.delete', {
            parent: 'utente',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/utente/utente-delete-dialog.html',
                    controller: 'UtenteDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Utente', function(Utente) {
                            return Utente.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('utente', null, { reload: 'utente' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
