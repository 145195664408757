(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Campagnalog_tipoDialogController', Campagnalog_tipoDialogController);

    Campagnalog_tipoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Campagnalog_tipo'];

    function Campagnalog_tipoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Campagnalog_tipo) {
        var vm = this;

        vm.campagnalog_tipo = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.campagnalog_tipo.id !== null) {
                Campagnalog_tipo.update(vm.campagnalog_tipo, onSaveSuccess, onSaveError);
            } else {
                Campagnalog_tipo.save(vm.campagnalog_tipo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:campagnalog_tipoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
