(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('GiftcardStatementDetailController', GiftcardStatementDetailController);

    GiftcardStatementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GiftcardStatement', 'GcSystem', 'Sistema'];

    function GiftcardStatementDetailController($scope, $rootScope, $stateParams, previousState, entity, GiftcardStatement, GcSystem, Sistema) {
        var vm = this;

        vm.giftcardStatement = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:giftcardStatementUpdate', function(event, result) {
            vm.giftcardStatement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
