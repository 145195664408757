(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Delivery_option_agenziaDialogController', Delivery_option_agenziaDialogController);

    Delivery_option_agenziaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Delivery_option_agenzia', 'Delivery_option', 'Agenzia'];

    function Delivery_option_agenziaDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Delivery_option_agenzia, Delivery_option, Agenzia) {
        var vm = this;

        vm.delivery_option_agenzia = entity;
        vm.clear = clear;
        vm.save = save;
        vm.delivery_options = Delivery_option.query();
        vm.agenzias = Agenzia.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.delivery_option_agenzia.id !== null) {
                Delivery_option_agenzia.update(vm.delivery_option_agenzia, onSaveSuccess, onSaveError);
            } else {
                Delivery_option_agenzia.save(vm.delivery_option_agenzia, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:delivery_option_agenziaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
