(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('TvController', TvController);

    TvController.$inject = ['$scope', '$state', 'Tv'];

    function TvController ($scope, $state, Tv) {
        var vm = this;

        vm.tvs = [];

        loadAll();

        function loadAll() {
            Tv.query(function(result) {
                vm.tvs = result;
            });
        }
    }
})();
