(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('TabletController', TabletController);

    TabletController.$inject = ['$scope', '$state', 'Tablet'];

    function TabletController ($scope, $state, Tablet) {
        var vm = this;

        vm.tablets = [];

        loadAll();

        function loadAll() {
            Tablet.query(function(result) {
                vm.tablets = result;
            });
        }
    }
})();
