(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('QueueImportController',QueueImportController);

    QueueImportController.$inject = ['$uibModalInstance', 'entity', 'Queue'];

    function QueueImportController($uibModalInstance, entity, Queue) {
        var vm = this;

        vm.queue = entity;
        vm.clear = clear;
        vm.confirmImport = confirmImport;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmImport (id) {       	
        	
        	 Queue.import({id: id},
                     function () {
                         $uibModalInstance.close(true);
                     });
        }
        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:queueImport', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        
    }
})();
