(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Campagna_typeDeleteController',Campagna_typeDeleteController);

    Campagna_typeDeleteController.$inject = ['$uibModalInstance', 'entity', 'Campagna_type'];

    function Campagna_typeDeleteController($uibModalInstance, entity, Campagna_type) {
        var vm = this;

        vm.campagna_type = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Campagna_type.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
