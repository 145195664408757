(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('AttivitaController', AttivitaController);

    AttivitaController.$inject = ['$scope', '$state', 'Attivita'];

    function AttivitaController ($scope, $state, Attivita) {
        var vm = this;

        vm.attivitas = [];

        loadAll();

        function loadAll() {
            Attivita.query(function(result) {
                vm.attivitas = result;
            });
        }
    }
})();
