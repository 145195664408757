(function() {
    'use strict';
    angular
        .module('doctorclubboApp')
        .factory('Queue_scheduled', Queue_scheduled);

    Queue_scheduled.$inject = ['$resource', 'DateUtils'];

    function Queue_scheduled ($resource, DateUtils) {
        var resourceUrl =  'api/queue-scheduleds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dt_ins = DateUtils.convertDateTimeFromServer(data.dt_ins);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
