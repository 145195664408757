(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('ComuneDetailController', ComuneDetailController);

    ComuneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Comune'];

    function ComuneDetailController($scope, $rootScope, $stateParams, previousState, entity, Comune) {
        var vm = this;

        vm.comune = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:comuneUpdate', function(event, result) {
            vm.comune = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
