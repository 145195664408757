(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('SpecializzazioneDeleteController',SpecializzazioneDeleteController);

    SpecializzazioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'Specializzazione'];

    function SpecializzazioneDeleteController($uibModalInstance, entity, Specializzazione) {
        var vm = this;

        vm.specializzazione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Specializzazione.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
