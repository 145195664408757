(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Delivery_optionDialogController', Delivery_optionDialogController);

    Delivery_optionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Delivery_option'];

    function Delivery_optionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Delivery_option) {
        var vm = this;

        vm.delivery_option = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.delivery_option.id !== null) {
                Delivery_option.update(vm.delivery_option, onSaveSuccess, onSaveError);
            } else {
                Delivery_option.save(vm.delivery_option, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('doctorclubboApp:delivery_optionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
