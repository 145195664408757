(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('AgenziaDetailController', AgenziaDetailController);

    AgenziaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Agenzia', 'Comune', 'Provincia'];

    function AgenziaDetailController($scope, $rootScope, $stateParams, previousState, entity, Agenzia, Comune, Provincia) {
        var vm = this;

        vm.agenzia = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:agenziaUpdate', function(event, result) {
            vm.agenzia = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
