(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('StatementDetailController', StatementDetailController);

    StatementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Statement', 'Utente', 'Queue', 'StatementType', 'Campagna', 'Agenzia', 'User'];

    function StatementDetailController($scope, $rootScope, $stateParams, previousState, entity, Statement, Utente, Queue, StatementType, Campagna, Agenzia, User) {
        var vm = this;

        vm.statement = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:statementUpdate', function(event, result) {
            vm.statement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
