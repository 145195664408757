(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('UtenteDeleteController',UtenteDeleteController);

    UtenteDeleteController.$inject = ['$uibModalInstance', 'entity', 'Utente'];

    function UtenteDeleteController($uibModalInstance, entity, Utente) {
        var vm = this;

        vm.utente = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Utente.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
