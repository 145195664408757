(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('QueueAccountDetailController', QueueAccountDetailController);

    QueueAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'QueueAccount'];

    function QueueAccountDetailController($scope, $rootScope, $stateParams, previousState, entity, QueueAccount) {
        var vm = this;

        vm.queueAccount = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:queueAccountUpdate', function(event, result) {
            vm.queueAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
