(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Queue_scheduledDeleteController',Queue_scheduledDeleteController);

    Queue_scheduledDeleteController.$inject = ['$uibModalInstance', 'entity', 'Queue_scheduled'];

    function Queue_scheduledDeleteController($uibModalInstance, entity, Queue_scheduled) {
        var vm = this;

        vm.queue_scheduled = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Queue_scheduled.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
