(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('TvDetailController', TvDetailController);

    TvDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tv'];

    function TvDetailController($scope, $rootScope, $stateParams, previousState, entity, Tv) {
        var vm = this;

        vm.tv = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('doctorclubboApp:tvUpdate', function(event, result) {
            vm.tv = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
