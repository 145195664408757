(function() {
    'use strict';

    angular
        .module('doctorclubboApp')
        .controller('Recall_statusDeleteController',Recall_statusDeleteController);

    Recall_statusDeleteController.$inject = ['$uibModalInstance', 'entity', 'Recall_status'];

    function Recall_statusDeleteController($uibModalInstance, entity, Recall_status) {
        var vm = this;

        vm.recall_status = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Recall_status.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
